* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #ecc2c2;
  --font_color: #b0b0ae;
  --font_color_d36985: #d36985;
  --color_1c1c1b: #1c1c1b;
  --bg_color_sub: #525151;
  --color_242423: #242423;
  --alert: #c22c2c;
  --white_color: #fff;
}

body {
  width: 100%;
  height: 100%;
  background-color: var(--color_1c1c1b);
  color: var(--font_color);
  letter-spacing: 1.2px;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  -webkit-font-feature-settings: "pkna" 1, "palt" 1;
  font-feature-settings: "pkna" 1, "palt" 1;
}

a {
  text-decoration: none;
  color: var(--font_color);
}

li {
  list-style-type: none;
}

.alert {
  color: var(--alert);
  font-size: 14px;
}

.outline_pink_btn {
  background-color: inherit;
  color: var(--font_color_d36985);
  border: 1px solid var(--font_color_d36985);
}

.order_grey {
  color: var(--bg_color_sub);
}

.normal_btn {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  border: none;
}

button {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
}

button:disabled {
  opacity: 0.5;
}

a.normal_btn,
a.outline_grey_btn,
a.outline_pink_btn {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  display: block;
}

.button_flex {
  display: flex;
  justify-content: space-between;
}

.button_flex button {
  flex-basis: 49%;
}

.button_flex a {
  flex-basis: 49%;
}

.button_center {
  margin: auto 20% 20px 20%;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid var(--bg_color_sub);
  background-color: var(--color_242423);
  padding: 0 10px;
  color: var(--font_color);
}

.qr_link {
  text-align: center;
}

.qr_link a {
  color: var(--font_color);
}

select {
  padding-left: 15px;
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: var(--color_242423);
  color: var(--white_color);
  font-size: 14px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  outline: none;
}

textarea {
  height: 180px;
  font-size: 1rem;
  padding: 10px;
}

.product_image label {
  border: 1px solid var(--bg_color_sub);
  background-color: var(--color_242423);
  color: var(--font_color);
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
}

.product_image label input {
  display: none;
}

form>div {
  margin-bottom: 30px;
}

form>div:last-child {
  margin-bottom: 0;
}

.title {
  margin-bottom: 30px;
  text-align: center;
}

form {
  border-bottom: 1px solid var(--bg_color_sub);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.checkbox_area {
  display: flex;
  align-items: center;
}

.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 7px;
}

input[type="checkbox"] {
  display: none;
}

.checkbox::before {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  background-color: var(--white_color);
  border: 1px solid var(--font_color_d36985);
}

.orderListImage img {
  max-width: 100px;
}

.checkbox::after {
  opacity: 0;
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 15px;
  background-color: var(--font_color_d36985);
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

input[type="checkbox"]:checked+.checkbox::after {
  opacity: 1;
}

.required {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}

.any {
  background-color: var(--bg_color_sub);
  color: var(--bg-color);
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}

.form-title {
  margin-bottom: 10px;
}

.form-title p {
  display: inline-block;
}

.form-title p.card_annotation {
  display: block;
  font-size: 12px;
}

.form-title h2 {
  text-align: center;
}

.form-title .card_icon_list {
  display: flex;
  align-items: center;
  background-color: var(--bg_color_sub);
  border: 1px solid var(--font_color);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.form-title .card_icon_list img {
  width: 40px;
  margin-right: 10px;
}

.form-title .card_icon_list img.amex {
  width: 29px;
}

.form_checkbox_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_checkbox_flex input[type="text"] {
  width: 80%;
}

.form_post_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.form_post_flex input[type="text"] {
  width: fit-content;
}

.form_post_flex button {
  width: 40%;
  height: 38px;
  padding: 0;
  margin-left: 8px;
}

.wrapper {
  width: 100%;
  background-color: var(--color_1c1c1b);
}

.cont_wrapper {
  max-width: 900px;
  margin: 30px auto 100px;
}

.employeeTable .detail_button {
  width: 70px;
  padding: 10px;
  margin: 0 auto;
}

.employeeTable button {
  font-size: 16px;
}

#hamburger img {
  cursor: pointer;
}

.encas_cont_wrap {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 32px 104px 32px;
}

#header_sub {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  margin: 0 auto 47px auto;
  border-bottom: 1px solid var(--bg_color_sub);
}

#header_sub .header_icon {
  width: 233px;
  height: 63px;
}

#header {
  width: 100%;
  position: relative;
  border: 1px solid transparent;
}

.header_top {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 120px;
  background-color: var(--color_1c1c1b);
  z-index: 999;
}

.header-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.header-right {
  display: flex;
}

.header-right .systemAdmin {
  margin-right: 24px;
}

#header .header_icon {
  width: 180px;
  height: 49px;
  margin-left: 24px;
}

#hamburger {
  width: 64px;
  height: 64px;
  margin-right: 24px;
  z-index: 1000;
}

.menu_icon {
  display: block;
  width: 100%;
  height: 100%;
}

.close {
  display: block;
  width: 100%;
  height: 100%;
}

.menu {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 590px;
  background-color: var(--color_1c1c1b);
  z-index: 750;
}

.hidden {
  display: none;
}

.menu_wrapper {
  margin-top: 120px;
  margin-left: 101px;
  width: 400px;
  height: 484px;
}

.button_area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 130px;
  background-color: var(--color_242423);
}

.button_area span {
  width: 1px;
  height: 82px;
  margin: 0 57px;
  background-color: var(--bg_color_sub);
}

.edit_button img {
  width: 75px;
  height: auto;
}

.qr_button img {
  width: 61px;
  height: auto;
}

.arrow {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin-right: 14px;
  margin-left: -10px;
}

.arrow::before {
  position: absolute;
  top: 8px;
  left: 10px;
  content: "";
  width: 10px;
  height: 10px;
  border-top: solid 2px var(--font_color);
  border-right: solid 2px var(--font_color);
}

.arrow.arrow-right::before {
  transform: rotate(45deg);
}

.menu_wrapper ul li {
  margin-bottom: 15px;
}

.menu_wrapper ul li:nth-of-type(1) {
  margin-top: 35px;
}

.menu_wrapper ul a,
.menu_wrapper ul p {
  color: var(--font_color);
  font-size: 20px;
  transition: all 0.3s;
  cursor: pointer;
}

.menu_wrapper ul a:hover,
.menu_wrapper ul p:hover {
  opacity: 0.7;
}

.list_menu {
  width: 100%;
  height: 111px;
  margin: 120px auto 0 auto;
  background-color: var(--color_242423);
  overflow: hidden;
}

.list_menu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  height: 100%;
}

.list_menu ul li {
  display: flex;
  align-items: center;
}

.list_menu ul li svg {
  width: 74px;
  height: auto;
  fill: var(--font_color);
}

.list_menu ul li .active svg {
  fill: var(--main-color);
}

.list_menu ul li:after {
  content: "";
  display: block;
  width: 2px;
  height: 70px;
  margin: 0 34px;
  background-color: var(--bg_color_sub);
}

.list_menu ul li:last-child::after {
  content: none;
}

.header-center p {
  font-size: 1.5rem;
}

.header-center .authName {
  color: var(--main-color);
}

/* ダイアログ */
#dialog {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
}

#dialog .dialog_box .store_detail {
  height: 85vh;
  overflow-y: scroll;
}

#dialog .dialog_box #logout p,
#dialog .dialog_box #orderConfirm p,
#dialog .dialog_box #paymentConfirm p,
#dialog .dialog_box .deleteConfirm,
#dialog .dialog_box #paymentCompleteConfirm p {
  padding: 30px 0 30px;
  text-align: center;
  font-size: 18px;
}

.dialog_box {
  background: var(--color_1c1c1b);
  width: 450px;
  padding: 15px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
}

.dialogText {
  text-align: center;
  font-size: 18px;
}

.dialog_inner {
  padding: 30px;
}

#completeDialog {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

#completeDialog img {
  width: 100px;
}

#completeDialog p {
  margin-bottom: 50px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            店舗側 商品一覧画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.product_top_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #525151;
  padding-bottom: 30px;
}

.select_wrap {
  position: relative;
  width: 160px;
  height: 52px;
  border: 1px solid #525151;
  border-radius: 6px;
  cursor: pointer;
}

.select_wrap.bank_type {
  height: 38px;
  margin-right: 10px;
}

.select_wrap.bank_type::before {
  top: 16px;
}

.select_wrap::before {
  content: "";
  position: absolute;
  right: 16px;
  top: 22px;
  z-index: 100;
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--font_color);
  border-left: 2px solid var(--font_color);
  transform: translateY(-50%) rotate(-135deg);
}

.product_top_buttons .category_button {
  width: 130px;
  height: 52px;
  text-align: center;
  background-color: #1c1c1b;
  font-size: 16px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 8px;
}

.product_top_buttons select,
.product_top_buttons .category_button {
  cursor: pointer;
}

.product_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 326px;
  border-bottom: 2px solid #525151;
}

.product_item_img {
  min-width: 260px;
  width: 260px;
  height: 260px;
  border: 2px solid #525151;
}

.product_item_img.product_item_upload {
  margin: 20px auto;
  width: 300px;
  height: 300px;
}

.product_item_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_item_info {
  margin-left: 24px;
  height: 260px;
}

.product_item_buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

a.product_edit_button,
button.product_delete_button {
  width: 100px;
  font-size: 16px;
  border-radius: 6px;
}

.product_edit_button {
  margin-right: 16px;
  color: #1c1c1b;
  border: 1px solid #525151;
  text-align: center;
}

.product_item_title {
  margin-top: 16px;
  color: var(--white_color);
  font-size: 18px;
}

.product_item_detail {
  margin-top: 20px;
  font-size: 16px;
}

.product_stock {
  margin-top: 12px;
  text-align: right;
  font-size: 16px;
}

.product_price {
  text-align: right;
  font-size: 16px;
}

.product_stock span,
.product_price span {
  font-size: 20px;
}

.change_page_buttons {
  display: flex;
  justify-content: center;
  margin: 50px auto 100px auto;
  height: 50px;
}

.page_arrow {
  position: relative;
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}

.page_arrow::before {
  content: "";
  width: 14px;
  height: 14px;
  border-top: solid 2px var(--main-color);
  border-right: solid 2px var(--main-color);
  position: absolute;
  left: 2px;
  top: 16px;
}

.page_arrow.arrow_left::before {
  transform: rotate(-135deg);
}

.page_arrow.arrow_right::before {
  transform: rotate(45deg);
}

.page_button {
  margin: 0 8px;
  width: 50px;
  height: 50px;
  border: 1px solid var(--main-color);
  cursor: pointer;
}

.page_button p {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
  color: var(--main-color);
  font-size: 20px;
}

.page_1 {
  background-color: var(--main-color);
}

.page_1 p {
  color: var(--color_1c1c1b);
}

.page_dot {
  width: 50px;
  height: 50px;
}

.page_dot img {
  display: block;
  margin: 10px auto;
  width: 30px;
  height: 30px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
           商品登録(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.stock .alert.disabled {
  display: none;
}

.stock .disabled {
  background: rgb(255 255 255 / 13%);
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
           トップ画面LP(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
#header_lp {
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 25px 24px 0;
  width: 100%;
  background-color: var(--color_1c1c1b);
}

.header_top_lp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-bottom: 1px solid #525151;
  padding-bottom: 25px;
}

#header_lp .header_icon {
  width: 85px;
  height: auto;
  margin-left: 0;
}

#header_lp .header_button {
  display: flex;
}

#signup .signup_button {
  color: var(--main-color);
  margin-right: 10px;
}

#login .login_button {
  color: var(--main-color);
  margin-left: 10px;
}

.top_wrapper {
  margin: 130px auto;
  max-width: 840px;
}

.top_wrapper h2 {
  font-size: 17px;
  color: var(--main-color);
  text-align: center;
}

.top_wrapper .store_top_icon {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.text_wrapper {
  margin-bottom: 40px;
}

.top_text_flex {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.top_text_flex p {
  max-width: 95%;
}

.pink_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin-right: 10px;
}

.top_detail {
  margin-bottom: 60px;
}

.top_wrapper h3 {
  color: var(--main-color);
  margin-bottom: 20px;
}

.store_cont_icon {
  display: block;
  background-color: var(--color_242423);
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: relative;
}

.store_cont_icon img {
  display: block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.magazine {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;

}

.magazine img {
  width: calc(100%/3);
}

.pc_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pc_flex_text {
  max-width: 75%;
}

.payment_Request_inner {
  margin-bottom: 50px;
}

.payment_request_contents {
  margin: 0 auto 20px;
  width: fit-content;
  min-width: 200px;
}

.payment_request_contents h3 {
  font-size: 30px;
  text-align: end;
}

.payment_alert {
  text-align: center;
  line-height: 20px;
  margin-bottom: 20px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
           出金一覧画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.payment_button_area {
  text-align: end;
  padding: 20px 0;
  max-width: 200px;
  margin: 0 0 0 auto;
}

.table {
  margin-bottom: 20px;
  width: 100%;
  background-color: #1c1c1b;
  text-align: center;
  color: #b0b0ae;
  font-size: 16px;
  border-collapse: collapse;
  border-bottom: 2px solid #525151;
}

#tokushoho table tr td {
  padding: 10px 5px;
}

.request_button {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 8px;
}

.table tr:nth-child(odd) td {
  background-color: #242423;
}

.table th {
  background-color: #ecc2c2;
  color: #1c1c1b;
  font-weight: normal;
}

#paymentList .table th {
  min-width: 140px;
}

.table td,
.table th {
  border-left: 2px solid #525151;
  border-top: 2px solid #525151;
  padding: 5px;
}

.table td:first-child,
.table th:first-child {
  border-left: none;
}

.table tr:first-child td,
.table tr:first-child th {
  border-top: none;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
           売上管理画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.date_area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 131px;
  border-bottom: 2px solid #525151;
}

.date_arrow {
  position: relative;
  display: inline-block;
  padding: 20px;
  cursor: pointer;
}

.date_arrow::before {
  content: "";
  width: 20px;
  height: 20px;
  border-top: solid 4px #ecc2c2;
  border-right: solid 4px #ecc2c2;
  position: absolute;
  left: 2px;
  top: 10px;
}

.date_arrow.arrow_left::before {
  transform: rotate(-135deg);
}

.date_arrow.arrow_right::before {
  transform: rotate(45deg);
}

.date {
  margin: 0 120px;
  color: #ecc2c2;
  font-size: 38px;
}

.switch_button_area {
  display: flex;
  margin: 32px auto 42px auto;
  width: 501px;
  height: 80px;
  border: 2px solid #ecc2c2;
  border-radius: 40px;
}

.switch_button_area span {
  width: 2px;
  height: 100%;
  background-color: #ecc2c2;
}

.switch_button_area button {
  width: 167px;
  height: 100%;
  font-size: 24px;
  cursor: pointer;
  background-color: #1c1c1b;
  color: #ecc2c2;
  border: 1px solid transparent;
  padding: 0;
  border-radius: 0;
}

.switch_button_area button:last-child {
  border-radius: 0 40px 40px 0;
}

.switch_button_area button:first-child {
  border-radius: 40px 0 0 40px;
}

.switch_button_area button.current {
  background-color: #ecc2c2;
  color: #1c1c1b;
  border: 1px solid transparent;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
   管理者側 出金一覧画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
#paymentList .switch_button_area {
  width: 334px;
}

#paymentList td button {
  padding: 6px 15px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            店舗側 カテゴリー管理ダイアログ(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */

#category table button {
  max-width: 120px;
  padding: 8px;
}

#category table tr {
  height: 55px;
}

.ctg_info.table {
  border: 1px solid var(--bg_color_sub);
  margin-bottom: 80px;
}

.ctg_add_area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ctg_add_area .add_item_name {
  flex-basis: 78%;
}

.ctg_add_area .ctg_add_button {
  flex-basis: 20%;
}

.ctg_add_area .ctg_add_button button {
  padding: 8px;
}

.diagonal_line {
  background-image: linear-gradient(to right top,
      transparent calc(50% - 0.5px),
      var(--bg_color_sub) 50%,
      var(--bg_color_sub) calc(50% + 0.5px),
      transparent calc(50% + 1px));
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            QRコード画面
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
.qr_design {
  position: relative;
  width: 220px;
  margin: 0 auto 20px;
}

.qr_design span {
  width: 30px;
  height: 30px;
  position: absolute;
}

.qr_design img {
  display: block;
  margin: 0 auto;
  max-width: 200px;
}

#qr .alert {
  text-align: center;
  margin-bottom: 50px;
}

.qr_design span.qr_border_top_left {
  border-left: solid 5px var(--font_color);
  border-top: solid 5px var(--font_color);
  top: 0;
  left: 0;
}

.qr_design span.qr_border_top_right {
  border-right: solid 5px var(--font_color);
  border-top: solid 5px var(--font_color);
  top: 0;
  right: 0;
}

.qr_design span.qr_border_bottom_left {
  border-left: solid 5px var(--font_color);
  border-bottom: solid 5px var(--font_color);
  bottom: 0;
  left: 0;
}

.qr_design span.qr_border_bottom_right {
  border-right: solid 5px var(--font_color);
  border-bottom: solid 5px var(--font_color);
  bottom: 0;
  right: 0;
}

.qr_design::before {
  border-left: solid 5px var(--font_color);
  border-top: solid 5px var(--font_color);
  top: 0;
  left: 0;
}

.qr_design::after {
  border-right: solid 5px var(--font_color);
  border-bottom: solid 5px var(--font_color);
  bottom: 0;
  right: 0;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            ローディング画面
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
@keyframes ldio-yr7rj8lblh {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

#loading {
  position: fixed;
  z-index: 999999;
  background: rgb(0 0 0 / 60%);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.ldio-yr7rj8lblh div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 7.78px solid #ecc2c2;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-yr7rj8lblh div {
  animation: ldio-yr7rj8lblh 1s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingio-spinner-rolling-09ckrq18eb7s {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}

.ldio-yr7rj8lblh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yr7rj8lblh div {
  box-sizing: content-box;
}

/* パスワード再設定画面 */
.input_description {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
  color: #b0b0ae;
  font-size: 16px;
}

.error_text {
  display: none;
  margin-top: -18px;
  color: #c22c2c;
  font-size: 16px;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
従業員登録画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */

.form_post_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_post_flex input[type="text"] {
  width: fit-content;
}

.form_post_flex button {
  width: 40%;
  height: 38px;
  padding: 0;
  margin-left: 8px;
}

/* 条項 */
.term {
  display: flex;
  align-items: center;
}

.terms_policy p {
  display: inline-block;
  font-size: 16px;
}

.terms_policy p {
  color: #b0b0ae;
}

.terms_policy a {
  color: var(--main-color);
  text-decoration: none;
  border-bottom: 1px solid var(--main-color);
}

.terms_policy br {
  display: none;
}

/*  チェックボックス */

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked+.checkbox::before {
  background-color: var(--font_color_d36985);
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
              店舗側 従業員管理ダイアログ(PC)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
#employeeList .request_button {
  max-width: 200px;
  margin: 0 0 25px auto;
}

.dialog_button {
  cursor: pointer;
  padding: 15px;
  margin: 5px;
  border-radius: 8px;
  text-align: center;
  display: block;
}

.employee_edit_button,
.employee_delete_button {
  background-color: var(--main-color);
  color: var(--color_1c1c1b);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  padding: 5% 10%;
}

.employee_edit_button {
  width: 49%;
}

.normal_btn_delete {
  padding-top: 5%;
}

.dialog_table {
  margin-bottom: 80px;
  width: 100%;
  background-color: #1c1c1b;
  text-align: center;
  color: #b0b0ae;
  font-size: 14px;
  border-collapse: collapse;
  border-bottom: 2px solid #525151;
}

.dialog_table tr {
  height: 60px;
}

.dialog_table tr:nth-child(odd) td {
  background-color: #242423;
}

.dialog_table th {
  background-color: #ecc2c2;
  color: #1c1c1b;
  font-weight: normal;
}

.dialog_table td,
.dialog_table th {
  border-left: 2px solid #525151;
  border-top: 2px solid #525151;
  padding: 5px;
}

.dialog_table td:first-child,
.dialog_table th:first-child {
  border-left: none;
}

.dialog_table tr:first-child td,
.dialog_table tr:first-child th {
  border-top: none;
}

/* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            店舗側 店舗登録画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */

/* 出金申請用口座情報 */
.bank_name,
.branch_name,
.account_num,
.account_name {
  display: flex;
  margin-bottom: 8px;
}

.bank_name,
.branch_name {
  max-width: 300px;
}

.left_space {
  width: 25%;
  height: 52px;
  margin-right: 16px;
}

.right_space {
  width: 65px;
  height: 52px;
  line-height: 40px;
  margin-left: 16px;
}

.account_num .select_wrap_type {
  width: 35%;
  margin-right: 8px;
}

.account_num select {
  display: flex;
}

.account_num input[type="text"] {
  width: 78%;
}

.account_holder input[type="text"] {
  width: 115%;
}

/* 条項 */
.terms {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.terms_policy p {
  display: inline-block;
  font-size: 16px;
}

.terms_policy p {
  color: #b0b0ae;
}

.terms_policy a {
  color: var(--font_color_d36985);
  text-decoration: none;
  border-bottom: 1px solid var(--font_color_d36985);
}

.terms_policy br {
  display: none;
}

/*  チェックボックス */
input[type="checkbox"] {
  display: none;
}

/* .error_textにこのクラスを付与してエラーメッセージ表示 */

.error {
  display: block;
}

/* メールアイコン */
.iconMail {
  width: 150px;
  height: 150px;
  margin: auto;
  display: block;
}

.guide {
  font-size: 18px;
  margin: 22px auto 30px;
}

.guide p {
  text-align: center;
  margin: auto 10%;
}

.passReset {
  text-align: center;
  margin: 40px auto 30px;
}

#policy .logo {
  text-align: center;
  padding: 20px 0 60px;
}

#policy .logo img {
  width: 180px;
}

footer {
  text-align: center;
}

footer a {
  display: block;
  margin: 15px 0;
}

.table_wrap {
  overflow-x: scroll;
}

.login_option {
  text-align: center;
}

.login_option a {
  color: var(--font_color_d36985);
}

.StripeElement {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--bg_color_sub);
  background-color: var(--color_242423);
  padding: 10px;
  color: var(--font_color);
}

.card_number_area {
  margin-bottom: 15px;
}

.card_expiry_cvc_area {
  display: flex;
}

.card_expiry_element {
  margin-right: 15px;
}

.checkCardEdit>* {
  vertical-align: middle;
}

.currentCardInfo span {
  margin-right: 15px;
}

@media (max-width: 750px) {
  .cont_wrapper .employeeTable {
    overflow-x: scroll;
  }

  .employeeTable button {
    font-size: 12px;
  }

  #header_sub {
    height: 60px;
    margin: 0 auto 32px auto;
  }

  #header_sub .header_icon {
    width: 116px;
    height: 32px;
    margin-top: 5px;
  }

  .header_top {
    height: 61px;
  }

  .header-center {
    max-width: 139px;
  }

  .header-center p {
    font-size: 1.1rem;
  }

  #header .header_icon {
    width: 85px;
    height: 23px;
    margin-left: 12px;
  }

  .encas_cont_wrap {
    padding: 0 32px 75px 32px;
  }

  h1 {
    margin-right: 10%;
    font-size: 14px;
  }

  #hamburger {
    width: 45px;
    height: 45px;
    margin-right: 12px;
  }

  .menu {
    width: 240px;
  }

  .menu_wrapper {
    width: 100%;
    margin-top: 60px;
    margin-left: 0;
  }

  .button_area {
    height: 90px;
  }

  .button_area span {
    height: 66px;
    margin: 0 29px;
  }

  .edit_button img {
    width: 50px;
    height: auto;
  }

  .qr_button img {
    width: 40px;
    height: auto;
  }

  .arrow {
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .arrow::before {
    top: 10px;
    left: 10px;
    width: 8px;
    height: 8px;
    border-top: solid 2px var(--font_color);
    border-right: solid 2px var(--font_color);
  }

  .menu_wrapper ul li:nth-of-type(1) {
    margin-top: 28px;
  }

  .menu_wrapper ul a,
  .menu_wrapper ul p {
    font-size: 14px;
  }

  .list_menu {
    height: 64px;
    margin: 60px auto 0 auto;
  }

  .list_menu ul li svg {
    width: 45px;
  }

  .list_menu ul li:after {
    height: 41px;
    margin: 0 15px;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
              店舗側 商品一覧画面(SP)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  .product_top_buttons {
    height: 80px;
  }

  .select_wrap {
    height: 40px;
    border-radius: 4px;
  }

  .select_wrap::before {
    top: 17px;
  }

  .product_top_buttons select {
    padding-left: 15px;
    font-size: 8px;
    border-radius: 4px;
  }

  .product_top_buttons .category_button {
    width: 86px;
    height: 40px;
    font-size: 8px;
    border-radius: 4px;
  }

  .product_item {
    min-height: 224px;
  }

  .product_item_img {
    min-width: 171px;
    width: 171px;
    height: 171px;
    border: 1px solid #525151;
  }

  .product_item_info {
    margin-left: 8px;
    height: 171px;
  }

  a.product_edit_button,
  button.product_delete_button {
    width: 75px;
    font-size: 8px;
    border-radius: 8px;
  }

  .product_edit_button {
    margin-right: 8px;
  }

  .product_item_title {
    margin-top: 8px;
    font-size: 12px;
  }

  .product_item_detail {
    margin-top: 8px;
    font-size: 10px;
  }

  .product_stock {
    margin-top: 8px;
    font-size: 10px;
  }

  .product_price {
    margin-top: 4px;
    font-size: 10px;
  }

  .product_stock span,
  .product_price span {
    font-size: 12px;
  }

  .change_page_buttons {
    margin: 40px auto 75px auto;
    height: 40px;
  }

  .page_arrow {
    padding: 10px;
  }

  .page_arrow::before {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 16px;
  }

  .page_button {
    margin: 0 6px;
    width: 40px;
    height: 40px;
  }

  .page_button p {
    line-height: 40px;
    font-size: 14px;
  }

  .page_dot {
    width: 30px;
    height: 40px;
  }

  .page_dot img {
    width: 20px;
    height: 20px;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
             トップ画面LP(PC)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  .top_wrapper {
    margin: 130px 24px;
    max-width: auto;
  }

  .store_cont_icon {
    margin: 10px auto;
  }

  .top_wrapper h3 {
    text-align: center;
  }

  .pc_flex {
    display: block;
  }

  .pc_flex_text {
    max-width: inherit;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
          店舗側 出金一覧画面(PC)
ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */

  .payment_button_area .request_button {
    font-size: 9px;
  }

  .table {
    white-space: nowrap;
    font-size: 12px;
  }

  #tokushoho .table {
    white-space: normal;
  }

  .table tr:nth-child(odd) td {
    background-color: #242423;
  }

  .table th {
    background-color: #ecc2c2;
    color: #1c1c1b;
    font-weight: normal;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            管理者側 出金一覧画面(PC)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  #paymentList .switch_button_area {
    width: 166px;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
            売上管理画面(SP)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  .table_wrap {
    margin-right: calc((100% - 90%) / 2 * -1);
    padding-right: calc((100% - 90%) / 2);
    margin-left: calc((100% - 90%) / 2 * -1);
    padding-left: calc((100% - 90%) / 2);
  }

  .date_area {
    height: 65px;
  }

  .date_arrow::before {
    width: 10px;
    height: 10px;
    border-top: solid 2px #ecc2c2;
    border-right: solid 2px #ecc2c2;
    left: 15px;
    top: 15px;
  }

  .date {
    margin: 0 40px;
    font-size: 19px;
  }

  .switch_button_area {
    margin: 16px auto 21px auto;
    width: 249px;
    height: 40px;
    border: 1px solid #ecc2c2;
    border-radius: 20px;
  }

  .switch_button_area span {
    width: 1px;
  }

  .switch_button_area button {
    width: 83px;
    font-size: 12px;
  }

  .daily_button {
    border-radius: 20px 0 0 20px;
  }

  .customer_button {
    border-radius: 0 20px 20px 0;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
              店舗側 カテゴリー管理ダイアログ(SP)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */
  .ctg_info.table {
    margin-bottom: 40px;
  }

  /* ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー
              管理者側 店舗一覧画面 (SP)
  ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー */

  .detail_button_area {
    text-align: center;
  }

  .employeeTable .detail_button {
    padding: 10px 18px;
    border-radius: 8px;
  }
}

@media (max-width: 480px) {

  #tokushoho table tr th,
  #tokushoho table tr td {
    display: block;
  }

  #tokushoho table tr td {
    border-right: 2px solid var(--bg_color_sub);
    background-color: var(--color_242423);
  }

  .cont_wrapper {
    max-width: 90%;
  }

  .form_checkbox_flex input[type="text"] {
    width: 70%;
  }

  .dialog_box {
    width: 90%;
  }

  .magazine {
    display: block;

  }

  .magazine img {
    width: 100%;
  }
}